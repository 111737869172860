.addCircularWrapper {
    background-color: #f5f7f9;
    padding-left: 20px;
    height: 70vh;
    padding-right: 20px;
    padding-top: 30px;
    font-family: "Nunito", sans-serif !important;
}


.addHrPolicyHeading {
    color: #4f4f4f;
    /* font-weight: 600; */
}

.addHrPolicyHeading h1 {
    color: #4f4f4f;
    font-weight: 700;
    font-size: 30px;

}

.addHrPolicyHeading p {
    color: #4f4f4f;
    font-style: italic;
}

.addHrPolicyHeading p span {
    color: #2a82b9;
    font-weight: 700;
}

.leaveRequestBtn {
    position: absolute !important;
    right: 15px !important;
    top: 7px !important;
    font-size: 15px !important;
    height: 30px !important;

    color: white !important;
    padding: 0px 10px 0px 10px !important;
    background-color: #338687 !important;
    border-radius: 5px !important;


}


.addCircularTableData {
    position: relative;
    margin-top: 30px;
    margin-bottom: 20px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}



.addCircularTable Thead {
    background-color: #338687 !important;
    border-radius: 10px !important;
}


.addCircularTable Thead Th {
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
}

.addCircularTable Td {
    /* color: white; */

    padding-top: 5px;
    padding-bottom: 5px;
}



.addCircularInputField {
    background-color: white;    
}

.addCircularInputField .chakra-button {
    height: 100%;
    width: 100%;
    background-color: #3182CE;
    color: white;
}


/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .addEmployeeComponent
    {
    padding-left: 0px;
    padding-right: 0px;
    }
}