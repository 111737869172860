.dashboard {
    background-color: #f6f8fa;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: "Nunito", sans-serif !important;
}

.dashBoardContentOne {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #85acc5;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    border-radius: 10px;
    /* height: 200px; */
}

.dashboardLeftDiv {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
}


.hospitalInformation {
    background-color: white;
    /* border: 2px solid #d1d2d0; */
    border-radius: 10px;
    /* padding: 20px; */
}

.dashboardTable th {
    padding: 20px;
    font-size: 22px;
    background-color: #dce7fe;
    color: #4c5484;
}

table.dashboardTable {
    width: 100%;
}

.dashboardTable strong {
    color: #70716f;
}

.dashboardTable td {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.dashboardTable td {
    color: #70716f;
}

table.dashboardTable,
.dashboardTable th,
.dashboardTable td {
    border: 1px solid #d1d2d0;
    /* Sets a black border */
}

.dashboardRightDiv {
    /* background-color: red; */
    padding: 20px;
}



.dashboardRightDiv {
    padding: 30px;
}

.chakra-select {
    background-color: white !important;
}

.dashboardLabel {
    font-size: 22px !important;
    color: white;
    text-align: center !important;
    padding-top: 10px;
}

.dboardSearch {
    height: 100% !important;
    background-color: #5173eb !important;
    width: 100%;
    color: white !important;
    border-radius: 0px 5px 5px 0 !important;
}


@media (max-width: 768px) {
    .dashboard {
        padding: 0px;
        border-radius: none !important;
    }

    .dashBoardContentOne {
        padding-top: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .dashboardRightDiv {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .dashboardChild
    {
        width: 100% !important;
    }
}


@media (min-width: 576px) and (max-width: 768px) {}

@media (min-width: 768px) and (max-width: 992px) {}

@media (min-width: 992px) and (max-width: 1200px) {}

@media (min-width: 1200px) {}

.dashboardParent {
    display: flex;
    background-color: #1c889e;
    position: relative;
    overflow: visible;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 150px;
    margin-bottom: 50px;
    border-radius: 20px;
    flex-direction: row; /* Ensure children are side by side on larger screens */
}

.dashboardChild {
    position: relative;
    display: flex;
    flex-grow: 1;
    margin-left: 100px;
    align-items: center;
}

.dashboardChild img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    left: 20%;
    transform: translateX(-50%); /* Center the image within the child div */
    z-index: 0;
}

.dashboardChildText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    flex-grow: 1;
    margin-left:50%;
    padding: 12px;
    color: white;
}

.dashboardChildText h2 {
    font-size: 40px;
    margin-bottom: 0px;
    font-weight: 700;
}

.dashboardChildText p {
    margin-bottom: 0px;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .dashboardParent {
        flex-direction: column; /* Stack children vertically */
     
        margin-top: 50px; /* Adjust top margin for tighter mobile spacing */
    }

    .dashboardChild {
        flex-grow: 0; /* Remove flex-grow */
        width: 100%; /* Full width on mobile */
        margin-left: 0; /* Remove additional margin */
        padding-top: 10px;
        padding-bottom: 10px;
        
    }
  

    .dashboardChildText {
        margin-left: 0; /* Align text properly under the image */
        align-items: center; /* Center the text alignment */
        text-align: center; /* Center the text itself */
        padding: 0px; /* Increase padding for better spacing */
    }

    .dashboardRightDiv
    {
        margin-left: 20px;
        margin-right:20px;
    }

    .dashboardChild img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        position: absolute;
        left: 20%;
        transform: translateX(-50%);
        z-index: 0;
    }

}
