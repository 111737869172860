.addMasterWrapper {
    background-color: #f5f7f9;
    padding-left: 20px;
    height: 70vh;
    padding-right: 20px;
    padding-top: 30px;
    font-family: "Nunito", sans-serif !important;
}


.addDepartmentWrapper {
    /* background-color: red; */
    padding: 20px;
}

.addWorkingDepartmentWrapper {
    /* background-color: red; */
    padding: 20px;
}

.addDesignationWrapper {
    /* background-color: red; */
    padding: 20px;
}


.addDepartmentHeading {
    position: relative;
    background-color: #e8e8e8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.addDepartmentHeading .chakra-button {
    position: absolute;
    top: 0px;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    right: 0px;
    height: 100%;
    background-color: #338687;
    width: 30%;
    /* border-radius: 0px !important; */
}

.addDepartmentHeading h1 {
    color: #338687;
    font-size: 25px;
    padding-left: 15px;
    padding-top: 10px;
    font-weight: 700;
}


.viewDepartmentTable {
    background-color: white;
}

.viewDepartmentTable thead {
    background-color: #338687;

}

.viewDepartmentTable thead th {
    color: white;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .addMasterWrapper {
        padding-left: 5px;
        height: auto;
        padding-right: 5px;
        padding-top: 30px;
    }

    .addDepartmentWrapper {
        padding: 5px;
    }

    .addWorkingDepartmentWrapper {
        padding: 5px;
    }

    .addDesignationWrapper {
        padding: 5px;
    }
}


.addMasterWrapper
{
    height: 100%;
}


.addDepartmentStyling
{
    background-color: white;
}

.addMasterWrapper
{
    padding-bottom: 50px;
}

.addDepartmentStyling
{
    
}

.departmentHeading
{
    font-size: 28px;
    background-color:#338687;
    font-weight: 600;
    text-align: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* margin-bottom: 20px; */
}


.inputBox
{
    padding: 20px;
}

.depatmentBtn
{
    padding: 0px;
    margin-top: 10px;
}

.departmentData
{
    /* padding-top: 10px; */
    padding-left: 20px;
    padding-right: 20px;
}
.departmentData ul{
    padding-left: 0px;
    margin-top: 0px;
}