.addFormatWrapper {
    background-color: #f5f7f9;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    height: 100%;
    font-family: "Nunito", sans-serif !important;
}

.addFormatInputField {
    background-color: white !important;
}


.addFormatHeading {
    color: #4f4f4f;
    /* font-weight: 600; */
}

.addFormatHeading h1 {
    color: #4f4f4f;
    font-weight: 700;
    font-size: 30px;

}

.addFormatHeading p {
    color: #4f4f4f;
    font-style: italic;
}

.addFormatHeading p span {
    color: #2a82b9;
    font-weight: 700;
}
.ck.ck-editor__main>.ck-editor__editable{
    border: none !important;
    height: 350px;
}

.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content
{
    border: none !important;
    /* box-shadow: 0px 5px 9px -6px rgba(0,0,0,0.75); */


}

.ck.ck-toolbar__items{
    background-color: rgb(236, 236, 236);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   
}

.ck.ck-toolbar.ck-toolbar_grouping{
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}