* {
    font-family: "Nunito", sans-serif !important;
}

.searchEmployeeComponent {
    background-color: #f5f7f9;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    font-family: "Nunito", sans-serif !important;
}


.searchEmployeeHeading {
    color: #4f4f4f;
    /* font-weight: 600; */
}

.searchEmployeeHeading h1 {
    color: #4f4f4f;
    font-weight: 700;
    font-size: 30px;

}

.searchEmployeeHeading p {
    color: #4f4f4f;
    font-style: italic;
}

.searchEmployeeHeading p span {
    color: #2a82b9;
    font-weight: 700;
}

.searchEmployeeTable {
    background-color: white;
}

.searchEmployeeSearchBtn {
    width: 50%;
    height: 38px !important;
    margin-top: 30px;
}

.searchEmployeeFormGroup {
    padding-top: 15px;
}

.searchEmployeeFormGroup label {
    font-weight: 700;
    color: #4f4f4f;
}

.searchEmployeeFormGroup input {
    background-color: white;
}

.searchEmployeeDate {
    padding-top: 7px;
    padding-bottom: 7px;
}


/* search table formatting */
.searchEmployeeTable {
    margin-top: 30px;
    /* background-color: white; */
}

.searchTable thead {
    background-color: #338687;
}

.searchTable thead th {
    color: white;
}

.searchTable thead th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.searchTable thead th:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.searchTable tbody {
    background-color: #ffffff;
}

.searchTable tbody td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.searchTable tbody td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}



.searchTable .workingDeptSpan {
    background-color: #f5f7f9;
    padding-top: 2px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;
    padding-bottom: 2px;

}

.searchTableJoiningDate {
    background-color: #e7fbe6;
    color: #21681d;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
}

.searchTableResigngDate {
    background-color: #fbe6e9;
    color: #681d1d;
    padding-left: 10px;
    margin-top: 10px;
    padding-right: 10px;
    border-radius: 50px;
}

.searchTable p {
    font-weight: 600;
    margin-bottom: 10px;
}

.searchTableBtn {
    border-radius: 50px !important;
    color: white !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    background-color: #338687 !important;

}



/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .searchEmployeeComponent {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.searchTable {
    border-collapse: collapse;
    width: 100%;
  }
  
  .tableBordered td{
    border-right: 1px solid #ddd; /* Add this line to create vertical borders */
    padding: 8px;
  }
  
  .searchTable  th {
    text-align: left;
  }