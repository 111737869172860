/* Add this CSS in your component or an external stylesheet */
@media print {
    body {
      font-size: 12px; /* Adjust the base font size */
      margin: 0; /* Remove default margin */
      padding: 0; /* Remove default padding */
    }
  
    .print-section {
      margin: 0;
      padding: 0;
    }
  
    .no-print {
      display: none !important;
    }
    .printable-section {
      margin: 0;
      padding: 0;
    }
    .print-header {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }


    .addEmployeeComponent {
      font-size: 12px; /* Adjust the font size of the main container */
      margin-top: 0; /* Reduce top margin */
      padding-top: 0; /* Reduce top padding */
    }
  
    .addEmployeeFieldSection,
    .addEmployeeSectionsHeading,
    .addEmployeeFormGroup,
    .wrapAddEmployeeSections,
    .addEmployeeDocWrapper,
    .addEmployeeDocSection {
      font-size: 12px; /* Adjust the font size of other sections */
      margin-top: 0; /* Reduce top margin */
      padding-top: 0; /* Reduce top padding */
    }
  
    /* Reduce font size and adjust spacing for specific elements */
    h1, h2, h3, h4, h5, h6 {
      font-size: 16px; /* Adjust heading sizes */
      margin-top: 0; /* Reduce top margin */
      padding-top: 0; /* Reduce top padding */
    }
  
    p, label, input, textarea, select {
      font-size: 12px; /* Adjust paragraph and form element sizes */
      margin-top: 0; /* Reduce top margin */
      padding-top: 0; /* Reduce top padding */
    }
  
    .addEmployeeHeading p {
      font-size: 10px; /* Adjust specific paragraph sizes if needed */
      margin-top: 0; /* Reduce top margin */
      padding-top: 0; /* Reduce top padding */
    }
  
    /* Ensure the print button is not printed */
    .print-button {
      display: none;
    }
  }
  