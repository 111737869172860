.leaveRequestWrapper {
    background-color: #f5f7f9;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    font-family: "Nunito", sans-serif !important;
}



.bgLeaveColor{
    background-color: #f5f7f9;
}

.leaveRequestHeading {
    color: #4f4f4f;
    /* font-weight: 600; */
}

.leaveRequestHeading h1 {
    color: #4f4f4f;
    font-weight: 700;
    font-size: 30px;

}

.leaveRequestHeading p {
    color: #4f4f4f;
    font-style: italic;
}

.leaveRequestHeading p span {
    color: #2a82b9;
    font-weight: 700;
}




.leaveSectionsHeading {

    margin-bottom: 10px;
}

.leaveSectionsHeading h1 {
    color: #338687;
    margin: 0px;
    font-size: 25px;
    padding-bottom: 5px;
    font-weight: 700;
}

.leaveSectionsHeading hr {
    width: 100%;
    color: #338687;
    border: 1px solid;
    opacity: 1;
    margin: 0px;
}


.leaveRequestSearchWrapper {
    position: relative;
    margin-top: 30px;
    margin-bottom: 20px;
    /* background-color: white; */
    padding: 10px;
    border-radius: 10px;
}


.leaveRequestBtn {
    position: absolute !important;
    right: 15px !important;
    top: 7px !important;
    font-size: 15px !important;
    height: 30px !important;
    color: white !important;
    padding: 0px 10px 0px 10px !important;
    background-color: #338687 !important;
    border-radius: 5px !important;

}


.leaveRequestTableFormat Thead {
    background-color: #338687 !important;
    border-radius: 10px !important;
}


.leaveRequestTableFormat Thead Th {
    color: white;
    /* padding-top: 5px; */
    /* padding-bottom: 5px; */
}




.viewLeaveBtn {
    margin-top: 45px;
    /* padding: 0px; */
}

.viewLeaveRequestTable {
    margin-top: 30px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */

}

.viewTableLeaveReason {
    padding-top: 7px;
    font-size: 18px;
    font-weight: 600;

}

.addLeaveRequestSearchInput {
    background-color: white;
}

.addLeaveRequestSearchInput .chakra-button {
    height: 100%;
    width: 100%;
    background-color: #3182CE;
    color: white;
}

.leaveDateStyling {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 7px;
    border: 0.5px solid #e3e3e3;
}

.leaveDateBtn
{
    margin-top: 40px;
}