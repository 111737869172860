.issueFormatWrapper {
    background-color: #f5f7f9;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    height: 60vh;
    font-family: "Nunito", sans-serif !important;
}

.issueFormatBtn {
    margin-top: 45px;
}

.issueFormatInput {
    background-color: white !important;
}


.issueFormatHeading {
    color: #4f4f4f;
    /* font-weight: 600; */
}

.issueFormatHeading h1 {
    color: #4f4f4f;
    font-weight: 700;
    font-size: 30px;

}

.issueFormatHeading p {
    color: #4f4f4f;
    font-style: italic;
}

.issueFormatHeading p span {
    color: #2a82b9;
    font-weight: 700;
}


#printingValue
{
    padding-top: 30px;
}



@media print {
    body {
      -webkit-print-color-adjust: exact;
      margin: 0;
      padding: 0;
    }
  
    @page {
      margin-top: 40mm; /* Top, bottom, left, and right margins are 20mm */
      margin-bottom: 40mm; /* Top, bottom, left, and right margins are 20mm */
      margin-left: 20mm; /* Top, bottom, left, and right margins are 20mm */
      margin-right: 20mm; /* Top, bottom, left, and right margins are 20mm */
    }
  
    #printableContent {
      width: 100%;
      margin: 0;
      padding: 0;
      font-family: Arial, sans-serif;
      page-break-inside: avoid; /* Prevents breaking inside the element */
    }
  
    /* Hide the print button when printing */
    button {
      display: none !important;
    }
  }
  
  