/* first nav properties */
.navFirst {
    background-color: #2e4c8d;
    font-family: "Nunito", sans-serif !important;
    padding-left: 30px;
    padding-right: 30px;
    height: auto !important;
}

.navMenuList {
    font-size: 20px;
}


.navText {
    color: white;
    margin-bottom: 0px;
    margin-right: 10px;
}

.logoImgDiv {
    display: flex;
    align-items: center;
}





.logoImgDiv img {
    width: 7%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.logoImgDiv h4 {
    font-size: 28px;
    margin-bottom: 0px;
    color: white;
    padding-left: 10px;
}




/* second nav properties */
.navSecond {
    color: #70716f;
    font-family: "Nunito", sans-serif !important;
    background-color: #ffffff;
}

.nav-item span {
    padding-left: 10px;

    font-size: 18px;
    font-weight: 600;
}

.nav-item a i {
    font-size: 12px;
}

.nav-item {
    padding-left: 12px;
    padding-right: 12px;
}

.navbar {
    padding-top: 15px;
    padding-bottom: 15px;
}

.nav-item a {
    padding: 0px !important;
}



@media (max-width: 768px) {

    /* CSS rules */
    .navSecond {
        display: none !important;
    }

    .navFirst {
        padding-left: 20px;
        padding-right: 20px;
    }

    .logoImgDiv h4 {
        font-size:18px;
    }
    .logoImgDiv img{
        width: 50px;
    }

    .mobileNav a{
        background-color: #f5f7f9 !important;
    }
  

    .mobileNav
    {
        margin-right: 20px;
        margin-top: 10px;
        margin-left: 20px;
    }

}

@media (min-width: 576px) and (max-width: 768px) {
    /* CSS rules */
}

@media (min-width: 768px) and (max-width: 992px) {
    /* CSS rules */
}

@media (min-width: 992px) and (max-width: 1200px) {
    /* CSS rules */
}

@media (min-width: 1200px) {
    /* CSS rules */
}