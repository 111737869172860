
/* Default styles for mobile phones */

.loginBox
{
    border-radius: 10px;
}

.loginBoxHeading
{
    border-radius: 10px 10px 0 0;
    font-size:30px !important;
    font-family: "Nunito", sans-serif !important;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 50px;
    background-color: red;
    padding-right: 50px;
}

.loginForm
{
    padding: 30px;
}

.LoginFormEyeIcon
{
    background: #EDF2F7;
}

.LoginFormEyeIconBtn
{
    font-size: 20px !important;
}

.FormSubmitBtn
{
    width: 100%;
    background-color: #1fc8db;
    background-image: linear-gradient(141deg, #2c6e97, #58b8f4 75%) !important;
}

.loginLogo
{
    position: absolute;
    top: 30px;
    left: 30px;
}

.loginLogo img
{
    width: 30%;
}

.loginFormLabel
{
    font-weight: 800 !important;
    font-size: 18px !important;
    color: #495057 !important;
}

.loginFormInput::placeholder{
    font-size: 16px;
    font-family: "Nunito", sans-serif !important;
}

.loginFormInput{
    font-weight: 600;
    color: #495057;
    font-size: 18px;
    font-family: "Nunito", sans-serif !important;
    padding: .47rem .75rem !;
}

.loginFormCheckbox
{
    font-size: 30px !important;
}





  
  /* Medium devices (tablets, 600px and up) */
  @media (min-width: 600px) {
   
  }
  
  /* Large devices (desktops, 900px and up) */
  @media (min-width: 900px) {
    body {
      background-color: orange;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    body {
      background-color: lightgreen;
    }
  }
  