* {
    font-family: "Nunito", sans-serif !important;
}

.addEmployeeComponent {
    background-color: #f5f7f9;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 50px;
    font-family: "Nunito", sans-serif !important;
}

.addEmployeeFieldSection {
    background-color: white;

    border-radius: 10px;
    font-family: "Nunito", sans-serif !important;
}

.addEmployeeFieldSectionForm {
    padding: 20px;
}

.addEmployeeHeading {
    color: #4f4f4f;
    /* font-weight: 600; */
}

.addEmployeeHeading h1 {
    color: #4f4f4f;
    font-weight: 700;
    font-size: 30px;

}

.addEmployeeHeading p {
    color: #4f4f4f;
    font-style: italic;
}

.addEmployeeHeading p span {
    color: #2a82b9;
    font-weight: 700;
}

.addEmployeeFieldSectionHeading {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: #4f4f4f;
    background-color: #e8e8e8;
    padding: 10px 10px 10px 20px;
}

.addEmployeeFieldSectionHeading h2 {
    margin-bottom: 0px;
    font-size: 22px;
    font-weight: 600;
}

.addEmployeeFormGroup {
    padding-top: 15px;
}

.addEmployeeFormGroup label {
    font-weight: 700;
    color: #4f4f4f;
}

.addEmployeeSectionsHeading {

    margin-bottom: 10px;
}

.addEmployeeSectionsHeading h1 {
    color: #338687;
    margin: 0px;
    font-size: 25px;
    padding-bottom: 5px;
    font-weight: 700;
}

.addEmployeeSectionsHeading hr {
    width: 100%;
    color: #338687;
    border: 1px solid;
    opacity: 1;
    margin: 0px;
}

.wrapAddEmployeeSections {
    position: relative;
    margin-top: 30px;
    margin-bottom: 20px;
    background-color: #f5f7f9;
    padding: 10px;
    border-radius: 10px;
}

.addEmployeeBtn {
    position: absolute !important;
    right: 15px !important;
    top: 7px !important;
    font-size: 15px !important;
    height: 30px !important;

    color: white !important;
    padding: 0px 10px 0px 10px !important;
    background-color: #338687 !important;
    border-radius: 5px !important;

}

.AddDependIcon {
    font-size: 15px;
    padding-right: 7px;
}

.addEmployeeTableFormat Thead {
    background-color: #338687 !important;
    border-radius: 10px !important;
}


.addEmployeeTableFormat Thead Th {
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
}

.addEmployeeTableFormat Td {
    /* color: white; */

    padding-top: 5px;
    padding-bottom: 5px;
}



/* Document Section CSS Properties */




.addEmployeeDocSection
{
    background-color: white;
    border-radius: 10px;
}

.addEmployeeDocForm Thead
{ 
    background-color: #338687;
    color: white;
}

.addEmployeeDocForm Thead th{
    color:white;
}

.addEmployeeDocBtn
{
    /* height: 30px !important; */
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.addEmployeeDocForm Th{
    padding-top: 10px;
    padding-bottom: 10px;
}
.addEmployeeDocForm Td{
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.modelFormFormatting thead th
{
    color: white !important;
}
.addTrainingButton
{
    position: absolute;
    top: 7px;
    right: 15px;
}

.trainingEachBtn
{
    font-size: 15px !important;
    height: 30px !important;
    color: white !important;
    padding: 0px 10px 0px 10px !important;
    background-color: #338687 !important;
    border-radius: 5px !important;
}

.docTableOne
{
    padding: 20px;
}

.profilePictures
{
    border: 3px solid #6F9AF4;
}
.addButtonEmp
{
    margin-top: 30px;
}

